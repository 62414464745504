// This /privacy-policy page is needed as its own page because applied for services like Google Cloud Calendar API OAuth consent screen requires a privacy policy and terms of service URLs. 
// This page will be linked to from the OAuth consent screen. The privacy policy is also linked to from the footer of the homepage. 
// The privacy policy is a static page that does not require any dynamic data

import React from 'react';
import Andr3wActiveWordLogo from "./Andr3wActiveWordLogo";
import PrivacyPolicy from "./PrivacyPolicy";
import { Menubar } from 'primereact/menubar';
import Andr3wActiveFooterLogo from './Andr3wActiveFooterLogo';

const PrivacyPage: React.FC = () => {
    const menuItems = [
        { label: 'HOME', command: () => { window.location.href = '/'; } },
    ];

    return (
        <div className="flex flex-column min-h-screen">
            <Menubar model={menuItems} className="bg-black-alpha-90 w-full z-5" />
            
            <div className="flex-grow-1 flex justify-content-center">
                <div className="w-full max-w-7xl p-3 sm:p-4">
                    <header className="flex align-items-center justify-content-between mt-6 mb-4">
                        <div className="flex align-items-center">
                            <Andr3wActiveWordLogo />
                        </div>
                    </header>

                    <main className="mb-6">
                        <PrivacyPolicy />
                    </main>

                    <footer className="flex align-items-center justify-content-between p-3 mt-6 common-aug-mixin common-aug-bl-mixin" data-augmented-ui="tl-clip tr-clip both">
                        <div className="flex align-items-center flex-wrap">
                            <Andr3wActiveFooterLogo />
                            <p className="m-0 ml-2 sm:ml-6 text-sm sm:text-base">
                                © 2024 ANDR3W.ai | All Rights Reserved |{' '}
                                <span
                                    className="text-yellow-500 cursor-pointer hover:text-yellow-300"
                                    onClick={() => window.location.href = '/terms-of-service'}
                                    style={{ textDecoration: 'underline' }}
                                >
                                    Terms of Service
                                </span>
                            </p>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPage;