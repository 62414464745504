// PrivacyPolicy.tsx
import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="p-3 text-left">
            <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
            <p className="mb-3">
                ANDR3W AI ("ANDR3W", "we", "us", or "our"), located at 1173 South 250 West, Suite 507, Saint George, UT 84770, United States, is committed to protecting your privacy.
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our services.
            </p>

            <h3 className="text-xl font-bold mb-2">1. Information We Collect</h3>
            <p className="mb-3">
                We collect information that you provide directly to us, information we obtain automatically when you use our Services, and information from third-party sources. This may include:
            </p>
            <ul className="list-disc pl-5 mb-3">
                <li>Personal identifiers (e.g., name, email address, phone number)</li>
                <li>Account information</li>
                <li>Communication data (e.g., call recordings, transcripts)</li>
                <li>Usage data and analytics</li>
                <li>Device information</li>
                <li>Google Calendar data (e.g., event titles, times, attendee information)</li>
            </ul>

            <h3 className="text-xl font-bold mb-2">2. How We Use Your Information</h3>
            <p className="mb-3">We use the information we collect to:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Provide, maintain, and improve our Services</li>
                <li>Process transactions and send related information</li>
                <li>Send you technical notices, updates, security alerts, and support messages</li>
                <li>Respond to your comments, questions, and customer service requests</li>
                <li>Develop new products and services</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our Services</li>
                <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
                <li>Comply with legal obligations</li>
                <li>
                    <strong>Use Google Calendar data to:</strong>
                    <ul className="list-disc pl-5">
                        <li>Schedule, update, and delete calendar events on your behalf</li>
                        <li>Check your availability to prevent scheduling conflicts</li>
                        <li>Sync appointments and events between ANDR3W AI and your Google Calendar</li>
                    </ul>
                </li>
            </ul>

            <h3 className="text-xl font-bold mb-2">3. Sharing of Information</h3>
            <p className="mb-3">We may share your information with:</p>
            <ul className="list-disc pl-5 mb-3">
                <li>Service providers and partners who perform services on our behalf</li>
                <li>In response to legal process or when required by law</li>
                <li>To protect our rights, privacy, safety, or property</li>
                <li>In connection with a merger, sale, or transfer of business assets</li>
            </ul>
            <p className="mb-3">
                <strong>We do not share your Google Calendar data with third parties</strong> except as necessary to provide our Services or comply with legal obligations.
            </p>

            <h3 className="text-xl font-bold mb-2">4. Data Retention</h3>
            <p className="mb-3">
                We retain your information for as long as necessary to provide our Services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.
                Your Google Calendar data is only accessed and stored as needed to provide the scheduling functionalities and is kept no longer than necessary.
            </p>

            <h3 className="text-xl font-bold mb-2">5. Security</h3>
            <p className="mb-3">
                We implement appropriate technical and organizational measures to protect your information against unauthorized or unlawful processing, accidental loss, destruction, or damage.
                This includes encrypting data in transit and at rest, and restricting access to authorized personnel only.
            </p>

            <h3 className="text-xl font-bold mb-2">6. Your Rights and Choices</h3>
            <p className="mb-3">
                Depending on your location, you may have certain rights regarding your personal information, including:
            </p>
            <ul className="list-disc pl-5 mb-3">
                <li>Access to your personal information</li>
                <li>Correction of inaccurate information</li>
                <li>Deletion of your personal information</li>
                <li>Restriction of processing</li>
                <li>Data portability</li>
                <li>Objection to processing</li>
            </ul>
            <p className="mb-3">
                <strong>Managing Google Calendar Access:</strong> You can revoke ANDR3W AI's access to your Google Calendar at any time through your Google Account settings or by contacting us at privacy@andr3w.ai.
            </p>

            <h3 className="text-xl font-bold mb-2">7. Google's API Services User Data Policy Compliance</h3>
            <p className="mb-3">
                We comply with Google's API Services User Data Policy. We only use Google user data to provide or improve your experience with our Services.
                We do not use Google user data for advertising purposes or share it with third parties except as necessary to provide our Services.
            </p>

            <h3 className="text-xl font-bold mb-2">8. Use of Google Workspace API Data</h3>
            <p className="mb-3">
                <strong>No Use for Generalized AI/ML Model Training:</strong> We affirm that we do not use any data obtained through Google Workspace APIs, including Google Calendar data, to develop, improve, or train generalized artificial intelligence or machine learning models.
            </p>
            <p className="mb-3">
                <strong>No Transfer to Third Parties for AI/ML Training:</strong> We do not transfer your Google Workspace API data to any third-party AI or machine learning tools for the purpose of developing, improving, or training generalized models.
            </p>
            <p className="mb-3">
                <strong>Use of Data for Personalized Services Only:</strong> Any data obtained is used solely to provide personalized services directly to you, enhancing your experience with our scheduling functionalities.
            </p>

            <h3 className="text-xl font-bold mb-2">9. Children's Privacy</h3>
            <p className="mb-3">
                Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
                If you are under 13, please do not use our Services or provide any information to us.
            </p>

            <h3 className="text-xl font-bold mb-2">10. Changes to This Privacy Policy</h3>
            <p className="mb-3">
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
                Your continued use of the Services after any changes constitutes your acceptance of the new Privacy Policy.
            </p>

            <h3 className="text-xl font-bold mb-2">11. International Data Transfers</h3>
            <p className="mb-3">
                Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in these cases,
                including compliance with applicable data protection laws.
            </p>

            <h3 className="text-xl font-bold mb-2">12. Contact Us</h3>
            <p className="mb-3">
                If you have any questions about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p>
                ANDR3W AI<br />
                1173 South 250 West, Suite 507<br />
                Saint George, UT 84770<br />
                United States<br />
                Email: privacy@andr3w.ai
            </p>

            <p className="mt-6 font-bold">Last Updated: October 23, 2024</p>
        </div>
    );
};

export default PrivacyPolicy;
