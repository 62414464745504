// Andr3wHUD.tsx

import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/Andr3wHUD.module.css';
import Andr3wHUDSVG from "./Andr3wHUDSVG";

const Andr3wHUD: React.FC = () => {
    const [currentFaceIndex, setCurrentFaceIndex] = useState<number>(1);
    const [currentVoiceIndex, setCurrentVoiceIndex] = useState<number>(0);
    const [isMouseMoving, setIsMouseMoving] = useState<boolean>(false);
    const [isVoiceDisabled, setIsVoiceDisabled] = useState<boolean>(false);
    const mouseTimer = useRef<number | null>(null);
    const faceTimer = useRef<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const andr3wFaces = ['HUD-Andr3w-Right', 'HUD-Andr3w-Forward', 'HUD-Andr3w-Left'];
    const rings = ['HUD-RING-1', 'HUD-RING-2', 'HUD-RING-3'];
    const voiceElements = ['HUD-VOICE-1', 'HUD-VOICE-2', 'HUD-VOICE-3', 'HUD-VOICE-4', 'HUD-VOICE-5'];

    const getRandomDelay = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const switchFace = () => {
        if (!isMouseMoving) {
            setCurrentFaceIndex(prevIndex => (prevIndex + 1) % andr3wFaces.length);
        }
        
        if (faceTimer.current !== null) {
            clearTimeout(faceTimer.current);
        }
        
        faceTimer.current = window.setTimeout(switchFace, getRandomDelay(2000, 6000));
    };

    const switchVoice = () => {
        if (!isVoiceDisabled) {
            setCurrentVoiceIndex(prevIndex => (prevIndex + 1) % voiceElements.length);
        }
    };

    const cycleVoiceAnimation = () => {
        const iterations = getRandomDelay(20, 40);
        let currentIteration = 0;

        const cycle = () => {
            if (currentIteration < iterations) {
                switchVoice();
                currentIteration++;
                setTimeout(cycle, 250);
            } else {
                setIsVoiceDisabled(true);
                setTimeout(() => {
                    setIsVoiceDisabled(false);
                    cycleVoiceAnimation();
                }, getRandomDelay(3000, 8000));
            }
        };

        cycle();
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const containerCenterX = containerRect.left + containerRect.width / 2;
            
            if (mouseTimer.current !== null) {
                clearTimeout(mouseTimer.current);
            }
            setIsMouseMoving(true);

            let newIndex;
            if (event.clientX < containerCenterX - containerRect.width / 4) {
                newIndex = 2; // Look left
            } else if (event.clientX > containerCenterX + containerRect.width / 4) {
                newIndex = 0; // Look right
            } else {
                newIndex = 1; // Look forward
            }
            setCurrentFaceIndex(newIndex);

            mouseTimer.current = window.setTimeout(() => {
                setIsMouseMoving(false);
            }, 5000);
        }
    };

    const rotateRing = (ringId: string) => {
        const ring = document.getElementById(ringId);
        if (ring instanceof SVGGraphicsElement) {
            const bbox = ring.getBBox();
            const centerX = bbox.x + bbox.width / 2;
            const centerY = bbox.y + bbox.height / 2;
            let speed = (Math.random() * 0.2 + 0.1) * (Math.random() < 0.5 ? 1 : -1);
            let rotation = 0;
    
            const animate = () => {
                rotation += speed;
                ring.setAttribute('transform', `rotate(${rotation}, ${centerX}, ${centerY})`);
                requestAnimationFrame(animate);
            };
    
            const changeSpeed = () => {
                speed = (Math.random() * 0.2 + 0.1) * (Math.random() < 0.5 ? 1 : -1);
                setTimeout(changeSpeed, getRandomDelay(1000, 3000));
                // setTimeout(changeSpeed, getRandomDelay(5000, 10000));
            };
    
            animate();
            setTimeout(changeSpeed, getRandomDelay(1000, 3000));
            // setTimeout(changeSpeed, getRandomDelay(5000, 10000));
        }
    };

    useEffect(() => {
        switchFace(); // Initial face switch
        cycleVoiceAnimation();
        document.addEventListener('mousemove', handleMouseMove);

        rings.forEach(ringId => rotateRing(ringId));

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            if (mouseTimer.current !== null) {
                clearTimeout(mouseTimer.current);
            }
            if (faceTimer.current !== null) {
                clearTimeout(faceTimer.current); 
            }
        };
    }, []);

    useEffect(() => {
        andr3wFaces.forEach((faceId, index) => {
            const face = document.getElementById(faceId);
            if (face) {
                face.style.display = index === currentFaceIndex ? 'block' : 'none';
            }
        });
    }, [currentFaceIndex]);

    useEffect(() => {
        voiceElements.forEach((voiceId, index) => {
            const voice = document.getElementById(voiceId);
            if (voice) {
                if (isVoiceDisabled) {
                    voice.style.fill = 'var(--color-hud-graph-disabled)';
                    voice.style.display = index === 0 ? 'block' : 'none'; // Show only the first voice element when disabled
                } else {
                    voice.style.fill = 'var(--color-hud-voice)';
                    voice.style.display = index === currentVoiceIndex ? 'block' : 'none';
                }
            }
        });
    }, [currentVoiceIndex, isVoiceDisabled]);

    return (
        <div ref={containerRef} className={styles.hudContainer}>
            <Andr3wHUDSVG />
        </div>
    );
};

export default Andr3wHUD;