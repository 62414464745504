// CallDemoSidebar.tsx

import React, { useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputMask, InputMaskChangeEvent } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import axios, { AxiosError } from 'axios';

// Define an interface for the expected response data
interface ApiResponse {
    message: string;
}

interface CallDemoSidebarProps {
    visible: boolean;
    onHide: () => void;
    acsPhoneNumber: string;
    demoTitle: string;
    onOpenTermsOfService: () => void;
}

const CallDemoSidebar: React.FC<CallDemoSidebarProps> = ({ visible, onHide, acsPhoneNumber, demoTitle, onOpenTermsOfService }) => {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = useRef<Toast>(null);

    const handlePhoneChange = (e: InputMaskChangeEvent) => {
        setPhone(e.value ?? '');
    };

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const formatToE164 = (phoneNumber: string) => {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        return digitsOnly.length === 10 ? `+1${digitsOnly}` : `+${digitsOnly}`;
    };

    const apiUrl = `${process.env.REACT_APP_API_URL}/api/demo-calls`;
    console.log('API URL:', apiUrl);
    console.log('REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

    const handleSubmit = async () => {
        setSubmitted(true);

        if (firstName && email && phone && companyName && validateEmail(email) && acsPhoneNumber) {
            setLoading(true);
            try {
                const formattedPhone = formatToE164(phone);
                const payload = {
                    firstName,
                    email,
                    phoneNumber: formattedPhone,
                    acsPhoneNumberE164: acsPhoneNumber,
                    companyName
                };

                console.log('Sending payload:', payload);

                const response = await axios.post<ApiResponse>(apiUrl, payload);

                console.log('API Response:', response);

                if (response.status === 200 && response.data.message === "Demo call initiated successfully") {
                    toast.current?.show({
                        severity: 'success',
                        summary: 'Call Initiated',
                        detail: 'ANDR3W is calling you now!',
                        life: 3000
                    });
                    onHide();
                } else {
                    throw new Error(response.data.message || 'Unexpected response');
                }
            } catch (error) {
                console.error('Full error object:', error);

                let errorMessage = 'Failed to initiate call. Please try again later.';

                if (axios.isAxiosError(error)) {
                    const axiosError = error as AxiosError<ApiResponse>;
                    console.error('Axios error details:', {
                        response: axiosError.response,
                        request: axiosError.request,
                        message: axiosError.message
                    });

                    if (axiosError.response) {
                        console.error('Error response data:', axiosError.response.data);
                        console.error('Error response status:', axiosError.response.status);
                        console.error('Error response headers:', axiosError.response.headers);

                        if (axiosError.response.status === 429) {
                            errorMessage = 'Demo call limit exceeded. Please try again later.';
                        } else if (axiosError.response.data && axiosError.response.data.message) {
                            errorMessage = axiosError.response.data.message;
                        } else if (axiosError.response.status === 400) {
                            errorMessage = 'Invalid input. Please check your information and try again.';
                        } else if (axiosError.response.status === 500) {
                            errorMessage = 'Server error. Please try again later.';
                        }
                    } else if (axiosError.request) {
                        errorMessage = 'No response received from server. Please check your connection.';
                    } else {
                        errorMessage = axiosError.message || 'An unexpected error occurred.';
                    }
                } else {
                    console.error('Non-Axios error:', error);
                    errorMessage = (error as Error).message || 'An unexpected error occurred.';
                }

                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                    life: 5000
                });
            } finally {
                setLoading(false);
            }
        } else {
            console.error('Form validation failed:', { firstName, email, phone, companyName, acsPhoneNumber });
            toast.current?.show({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please fill in all required fields correctly.',
                life: 5000
            });
        }
    };

    const renderHeader = () => {
        return (
            <div className="flex align-items-center gap-2">
                <i className="pi pi-phone" style={{ fontSize: '1.5rem' }}></i>
                <span className="font-bold text-xl">Talk with ANDR3W - {demoTitle}</span>
            </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Sidebar
                visible={visible}
                position="bottom"
                onHide={onHide}
                className="demo-call-sidebar p-sidebar-lg common-aug-overlay-mixin"
                data-augmented-ui="tl-clip tr-clip both"
                style={{ width: '400px', height: '600px' }}
                header={renderHeader()}
            >
                <div className="p-fluid mt-4">
                    <div className="field mb-6">
                        <span className="p-float-label">
                            <InputText
                                id="firstName"
                                data-augmented-ui="bl-clip tr-clip both"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={classNames({ 'p-invalid': submitted && !firstName })}
                            />
                            <label htmlFor="firstName">First Name*</label>
                        </span>
                        {submitted && !firstName && <small className="p-error">First name is required.</small>}
                    </div>
                    <div className="field mb-6">
                        <span className="p-float-label">
                            <InputText
                                id="companyName"
                                data-augmented-ui="bl-clip tr-clip both"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                className={classNames({ 'p-invalid': submitted && !companyName })}
                            />
                            <label htmlFor="companyName">Company Name*</label>
                        </span>
                        {submitted && !companyName && <small className="p-error">Company name is required.</small>}
                    </div>
                    <div className="field mb-6">
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                data-augmented-ui="bl-clip tr-clip both"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={classNames({ 'p-invalid': submitted && (!email || !validateEmail(email)) })}
                            />
                            <label htmlFor="email">Email*</label>
                        </span>
                        {submitted && !email && <small className="p-error">Email is required.</small>}
                        {submitted && email && !validateEmail(email) && <small className="p-error">Please enter a valid email address.</small>}
                    </div>
                    <div className="field mb-6">
                        <span className="p-float-label">
                            <InputMask
                                id="phone"
                                data-augmented-ui="bl-clip tr-clip both"
                                mask="(999) 999-9999"
                                value={phone}
                                onChange={handlePhoneChange}
                                className={classNames({ 'p-invalid': submitted && !phone })}
                            />
                            <label htmlFor="phone">Phone Number*</label>
                        </span>
                        {submitted && !phone && <small className="p-error">Phone number is required.</small>}
                    </div>
                    <Button
                        label={loading ? 'Initiating Call...' : 'Call Me'}
                        className="p-button-lg w-full"
                        data-augmented-ui="tl-clip tr-clip both"
                        onClick={handleSubmit}
                        disabled={loading}
                    />
                    <div className="text-center mt-2 mb-2">
                        <small>
                            By clicking "Call Me", you agree to receive autodialed calls and emails from ANDR3W AI and agree to our{' '}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={onOpenTermsOfService}
                                style={{ textDecoration: 'underline' }}
                            >
                                Terms of Service
                            </span>.
                        </small>
                    </div>
                </div>

            </Sidebar>
        </>
    );
};

export default CallDemoSidebar;