// SecretConsole.tsx

import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import '../styles/SecretConsole.css';

interface SecretConsoleProps {
    visible: boolean;
    onHide: () => void;
    onDexisCommand: (title: string, phoneNumber: string) => void;
    dexisPhoneNumber: string;
}

interface ConsoleLine {
    type: 'input' | 'output';
    content: string;
}

const SecretConsole: React.FC<SecretConsoleProps> = ({ visible, onHide, onDexisCommand, dexisPhoneNumber }) => {
    const [lines, setLines] = useState<ConsoleLine[]>([]);
    const [currentInput, setCurrentInput] = useState('');
    const consoleRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (visible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [visible]);

    const scrollToBottom = () => {
        if (consoleRef.current) {
            consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [lines, currentInput]);

    const handleCommand = (command: string) => {
        setLines(prev => [...prev, { type: 'input', content: command }]);

        switch (command.toLowerCase().trim()) {
            case 'dexis':
                setLines(prev => [...prev, { type: 'output', content: 'Initiating DEXIS demo...' }]);
                setTimeout(() => {
                    setLines([]);
                    onHide();
                    onDexisCommand("DEXIS Demo", dexisPhoneNumber);
                }, 1000);
                break;
            case 'clear':
                setLines([]);
                break;
            case 'exit':
                setLines([]);
                onHide();
                break;
            case 'help':
                setLines(prev => [
                    ...prev,
                    { type: 'output', content: 'Available commands:' },
                    // { type: 'output', content: '- dexis: Open DEXIS demo' },
                    { type: 'output', content: '- clear: Clear console' },
                    { type: 'output', content: '- exit: Close console' }
                ]);
                break;
            default:
                setLines(prev => [...prev, { type: 'output', content: 'Unknown command.' }]);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleCommand(currentInput);
            setCurrentInput('');
        }
    };

    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            className="secret-console w-full sm:w-11 md:w-10 lg:w-8 xl:w-5 common-aug-overlay-mixin"
            contentClassName="bg-black-alpha-90"
            contentStyle={{ padding: '1rem' }}
            showHeader={false}
            data-augmented-ui="tl-2-clip-x tr-2-clip-y br-clip b-clip-x bl-2-clip-y border"
        >
            <div className="ml-8">
                <div className="flex justify-content-left flex-wrap pl-4">
                    <div className="flex align-items-end justify-content-left bg-primary font-bold border-round m-2 px-2 text-xs">ANDR3W CONSOLE</div>
                    <div className="flex align-items-end justify-content-left bg-primary font-bold border-round m-2 px-2 text-xs">[v0.24240.b2]</div>
                    <div className="flex align-items-end justify-content-left bg-primary font-bold border-round m-2 px-2 text-xs"></div>
                    <div className="flex justify-content-between align-items-center flex-wrap my-0">
                        <div className="flex align-items-end justify-content-left bg-primary font-bold border-round m-2 px-2"></div>
                        <div className="flex align-items-end justify-content-left bg-primary font-bold border-round m-2 px-2"></div>
                    </div>
                </div>
            </div>
            <div className="console-container crt-effect mt-5" data-augmented-ui="tl-clip tr-clip br-clip bl-clip inlay">
                <div ref={consoleRef} className="console-output">
                    {lines.map((line, index) => (
                        <div key={index} className={`console-line ${line.type}`}>
                            {line.type === 'input' && <span className="prompt-symbol">&#x276F;</span>}
                            {line.content}
                        </div>
                    ))}
                    <div className="console-line input">
                        <span className="prompt-symbol">&#x276F;</span>
                        <input
                            ref={inputRef}
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="console-input"
                            autoFocus
                        />
                        <span className="cursor"></span>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default SecretConsole;