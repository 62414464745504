// App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './styles/App.css';
import HomePage from "./HomePage";
import PrivacyPage from './components/PrivacyPage';
import TermsOfServicePage from './components/TermsOfServicePage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPage /> } />
          <Route path="/terms-of-service" element={<TermsOfServicePage /> } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;