import React, { useEffect, useRef } from 'react';

interface HubSpotContactUsFormProps {
    location: 'sidebar' | 'footer';
}

const HubSpotContactUsForm: React.FC<HubSpotContactUsFormProps> = ({ location }) => {
    const formContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (formContainerRef.current) {
            const iframe = document.createElement('iframe');
            iframe.src = 'https://share.hsforms.com/1XMAqwXNVSe22fSfGZG5kPgqajz9';
            iframe.style.width = '100%';
            iframe.style.height = '98%';
            iframe.style.border = 'none';
            formContainerRef.current.innerHTML = '';
            formContainerRef.current.appendChild(iframe);
        }
    }, []);

    return (
        <div 
            ref={formContainerRef} 
            className={`hubspot-form-container hubspot-form-${location}`}
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <p>Loading HubSpot form...</p>
        </div>
    );
};

export default HubSpotContactUsForm;